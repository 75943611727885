/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import Helmet from "react-helmet"
import { Popover, Transition } from '@headlessui/react'
import {
  BookmarkAltIcon,
  CalendarIcon,
  ChartBarIcon,
  CurrencyEuroIcon,
  CursorClickIcon,
  DesktopComputerIcon,
  MenuIcon,
  PhoneIcon,
  PencilIcon,
  SunIcon,
  PlayIcon,
  RefreshIcon,
  ShieldCheckIcon,
  SupportIcon,
  TranslateIcon,
  ViewGridIcon,
  XIcon,
  MailIcon,
  AcademicCapIcon,
  ShoppingCartIcon,
  UserAddIcon,
  MicrophoneIcon,
  ScaleIcon,
  InformationCircleIcon,
  SparklesIcon
} from '@heroicons/react/outline'
import { ChevronDownIcon, ExclamationIcon } from '@heroicons/react/solid'
import {Link, useLocation} from 'react-router-dom';
import SnastaLogo from '../assets/logo.svg';
import SnastaLogoGreen from '../assets/logo_green.svg';
import { useTranslation } from "react-i18next";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Header ({ pageMeta }) {
  const { t, i18n } = useTranslation();
  const services = [
    {
    name: t("header_translation"),
    description: t("header_translation_desc"),
    href: '/Translation',
    icon: PencilIcon,
  },
  {
    name: t("header_certified"),
    description: t("header_certified_desc"),
    href: '/CertifiedLegalTranslation',
    icon: ScaleIcon,
  },
/*
  {
    name: 'Technology',
    description: 'We leverage the latest translation technology',
    href: '#',
    icon: DesktopComputerIcon,
  },
  {
    name: 'Voice Over',
    description: 'We specalise in Irish to English and English to Irish translation',
    href: '#',
    icon: MicrophoneIcon,
  },
*/
  {
    name: t("header_pricing"),
    description: t("header_pricing_desc"),
    href: '/Pricing',
    icon: CurrencyEuroIcon,
  },
]
const langSelector = [
  { name: 'English', lang: 'en', curr_lang: t("lang"), icon: TranslateIcon },
  { name: 'Español', lang: 'es', curr_lang: t("lang"), icon: TranslateIcon },
  { name: 'Gaeilge', lang: 'ga', curr_lang: t("lang"), icon: TranslateIcon },
]
const resources = [
  {
    name: t("header_faq"),
    description: t("header_faq_desc"),
    href: '/FAQ',
    icon: SupportIcon,
  },
  /*
  {
    name: 'Memsource Resources',
    description: 'Memsource Resources for Irish translators',
    href: '#',
    icon: AcademicCapIcon,
  },
  {
    name: 'Pay an Invoice',
    description: 'See what meet-ups and other events we might be planning near you.',
    href: '#',
    icon: ShoppingCartIcon,
  },
  {
    name: 'Work With Us',
    description: 'See what meet-ups and other events we might be planning near you.',
    href: '#',
    icon: UserAddIcon,
  },
  */
]
const recentPosts = [
  {/*
  { id: 1, name: 'Boost your conversion rate', href: '#' },
  { id: 2, name: 'How to use search engine optimization to drive traffic to your site', href: '#' },
  { id: 3, name: 'Improve your customer experience', href: '#' },
  */}
]
const forceLanguageChange = (newLang) => {
  i18n.changeLanguage(newLang);
  window.location.reload(false);
};
  return (
<>
    <Helmet>
      <title>{`Snasta - Professional Irish Translation -  ${pageMeta.title}`}</title>
      <meta name="description" content={pageMeta.description} />
      <meta name="keywords" content={pageMeta.keywords.join(',')} />
      {t("lang") === 'en' &&
        <link rel="canonical" href={window.location.href} />
      }
    </Helmet>

    <div className="fixed z-50 bottom-0 inset-x-0 pb-2 sm:pb-5">
      <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
        <div className="p-2 rounded-lg bg-green-600 shadow-lg sm:p-3">
          <div className="flex items-center justify-between flex-wrap">
            <div className="w-0 flex-1 flex items-center">
              <span className="flex p-2 rounded-lg bg-green-800">
                <SunIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </span>
              <p className="ml-3 font-medium text-white">
                <span className="md:hidden">We'll be closed 23rd Dec to 10th Jan for the Christmas break.</span>
                <span className="hidden md:inline">We'll be closed the 23rd December to the 10th January for the Christmas break. We'll have a skeleton service for contract clients only.</span>
              </p>
            </div>

          </div>
        </div>
      </div>
    </div>

        <Popover className="relative">
          {({ open }) => (
            <>
              <div className="max-w-7xl mx-auto px-4 sm:px-6">
                <div className="flex justify-between items-center border-b border-green-400 border-opacity-25 py-6 md:justify-start md:space-x-10">
                  <div className="flex justify-start lg:w-0 lg:flex-1">
                  <Link to="/">
                      <span className="sr-only">Snasta</span>
                      <img
                        className="h-10 w-auto"
                        src={SnastaLogo}
                        alt=""
                      />
                    </Link>
                  </div>
                  <div className="-mr-2 -my-2 md:hidden">
                    <Popover.Button className="bg-transparent rounded-md p-2 inline-flex items-center justify-center text-white hover:text-white hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                      <span className="sr-only">Open menu</span>
                      <MenuIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                  <Popover.Group as="nav" className="hidden md:flex space-x-10">
                    <Popover className="relative">
                      {({ open }) => (
                        <>
                          <Popover.Button
                            className={classNames(
                              open ? 'text-white' : 'text-green-200',
                              'group bg-transparent p-1 rounded-md inline-flex items-center text-base font-medium hover:text-white focus:outline-none focus:ring-2 focus:ring-white'
                            )}
                          >
                            <span>{t("header_services")}</span>
                            <ChevronDownIcon
                              className={classNames(
                                open ? 'text-white' : 'text-green-200',
                                'ml-2 h-5 w-5 group-hover:text-white'
                              )}
                              aria-hidden="true"
                            />
                          </Popover.Button>

                          <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                          >
                            <Popover.Panel
                              static
                              className="absolute z-10 -ml-4 mt-3 transform w-screen max-w-md px-2 sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                            >
                              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                <div className="relative bg-white py-6 px-5 grid gap-6 sm:gap-8 sm:p-8">
                                  {services.map((item) => (
                                    <Link
                                      key={item.name}
                                      to={item.href}
                                      className="-m-3 rounded-lg p-3 flex items-start hover:bg-gray-50"
                                    >
                                      <item.icon className="flex-shrink-0 h-6 w-6 text-green-600" aria-hidden="true" />
                                      <div className="ml-4">
                                        <p className="text-base font-medium text-gray-900">{item.name}</p>
                                        <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                                      </div>
                                    </Link>
                                  ))}
                                </div>
                              </div>
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>

                    <Link to="AboutUs" className="text-base p-1 font-medium text-green-200 hover:text-white">
                      {t("header_aboutus")}
                    </Link>

                    <Popover className="relative">
                      {({ open }) => (
                        <>
                          <Popover.Button
                            className={classNames(
                              open ? 'text-white' : 'text-green-200',
                              'group bg-transparent p-1 rounded-md inline-flex items-center text-base font-medium hover:text-white focus:outline-none focus:ring-2 focus:ring-white'
                            )}
                          >
                            <span>{t("more")}</span>
                            <ChevronDownIcon
                              className={classNames(
                                open ? 'text-white' : 'text-green-200',
                                'ml-2 h-5 w-5 group-hover:text-white'
                              )}
                              aria-hidden="true"
                            />
                          </Popover.Button>

                          <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                          >
                            <Popover.Panel
                              static
                              className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0"
                            >
                              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                <div className="relative bg-white py-6 px-5 grid gap-6 sm:gap-8 sm:p-8">
                                  {resources.map((item) => (
                                    <Link
                                      key={item.name}
                                      to={item.href}
                                      className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                                    >
                                      <item.icon className="flex-shrink-0 h-6 w-6 text-green-600" aria-hidden="true" />
                                      <div className="ml-4">
                                        <p className="text-base font-medium text-gray-900">{item.name}</p>
                                        <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                                      </div>
                                    </Link>
                                  ))}
                                </div>
                                {/*
                                <div className="px-5 py-5 bg-gray-50 sm:px-8 sm:py-8">
                                  <div>
                                    <h3 className="text-sm tracking-wide font-medium text-gray-500 uppercase">
                                      Recent Posts
                                    </h3>
                                    <ul className="mt-4 space-y-4">
                                      {recentPosts.map((post) => (
                                        <li key={post.id} className="text-base truncate">
                                          <a href={post.href} className="font-medium text-gray-900 hover:text-gray-700">
                                            {post.name}
                                          </a>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                  <div className="mt-5 text-sm">
                                    <a href="#" className="font-medium text-green-600 hover:text-green-500">
                                      {' '}
                                      View all posts <span aria-hidden="true">&rarr;</span>
                                    </a>
                                  </div>
                                </div>*/}

                                <div className="px-5 py-5 bg-gray-100 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                                  {langSelector.map((item) => (
                                    item.lang !== item.curr_lang
                                    ? (
                                    <div key={item.name} className="flow-root">
                                      <div></div>
                                      <a
                                        onClick={() => forceLanguageChange(item.lang)}
                                        className="-m-3 rounded-md p-3 flex items-center text-base font-medium text-gray-900 hover:bg-gray-100 cursor-pointer"
                                      >
                                        <item.icon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                                        <span className="ml-3">{item.name}</span>
                                      </a>
                                    </div>
                                    )
                                    : null
                                  ))}
                                </div>

                              </div>
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>

                  </Popover.Group>
                  <div className="hidden md:flex items-center justify-end space-x-8 md:flex-1 lg:w-0">
                  <a
                      href="tel:+353539407070"
                      className="hidden lg:inline-flex whitespace-nowrap text-base font-medium items-center justify-center text-base text-green-100 hover:text-white"
                    ><PhoneIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                      053 940 70 70
                    </a>
                    <a
                      href="mailto:info@snasta.ie"
                      className="whitespace-nowrap bg-white border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex items-center justify-center text-base font-medium text-green-600 hover:bg-gray-100"
                    ><MailIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                      {t("emailus")}
                    </a>
                  </div>
                </div>
              </div>

              <Transition
                show={open}
                as={Fragment}
                enter="duration-200 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Popover.Panel
                  focus
                  static
                  className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
                >
                  <div className="rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y-2 divide-gray-50 focus:outline-none">
                    <div className="pt-5 pb-6 px-5 space-y-6">
                      <div className="flex items-center justify-between">
                      <div>
                      <Link className="focus:outline-none" to="/"><img
                            className="h-10"
                            src={SnastaLogoGreen}
                            alt=""
                          />
                        </Link></div>
                        <div className="-mr-2">
                          <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500">
                            <span className="sr-only">Close menu</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </Popover.Button>
                        </div>
                      </div>
                      <div className="mt-6">
                        <nav className="grid gap-y-8">
                          {services.map((item) => (
                            <Link
                              key={item.name}
                              to={item.href}
                              className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                            >
                              <item.icon className="flex-shrink-0 h-6 w-6 text-green-600" aria-hidden="true" />
                              <span className="ml-3 text-base font-medium text-gray-900">{item.name}</span>
                            </Link>
                          ))}
                        </nav>
                      </div>
                    </div>
                    <div className="py-6 px-5 space-y-6">
                      <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                        <Link to="AboutUs" className="text-base font-medium text-gray-900 hover:text-gray-700">
                          {t("header_aboutus")}
                        </Link>

                        {resources.map((item) => (
                          <Link
                            key={item.name}
                            to={item.href}
                            className="text-base font-medium text-gray-900 hover:text-gray-700"
                          >
                            {item.name}
                          </Link>
                        ))}
                      </div>
                    </div>
                       <div className="px-5 py-5 space-y-6">
                                  {langSelector.map((item) => (
                                    item.lang !== item.curr_lang
                                    ? (
                                    <div key={item.name} className="flow-root">
                                      <div></div>
                                      <a
                                        onClick={() => forceLanguageChange(item.lang)}
                                        className="-m-3 rounded-md p-3 flex items-center text-base font-medium text-gray-900 hover:bg-gray-100 cursor-pointer"
                                      >
                                        <item.icon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                                        <span className="ml-3">{item.name}</span>
                                      </a>
                                    </div>
                                    )
                                    : null
                                  ))}
                                </div> 
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
        </>
  )
                        }

  export default Header;